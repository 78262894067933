<template>
  <div>
    <div class="fogyaszdfeleloseggel-bg-container">
      <div class="fogyaszdfeleloseggel-bg">
        <div class="container resp-row">
          <div class="fogyaszdfeleloseggel-menu col-md-12 col-lg-4" v-inview="'fade-up'">
            <p class="text--faq-menu-title text--gold">Tények</p>
            <div class="fogyaszdfeleloseggel-divider-menu">
              <a href="#fogyaszd_feleloseggel_1" v-scroll-to="'#fogyaszd_feleloseggel_1'"
                 class="text--faq-menu-text faq-btn-container faq-btn-container-margin"
                 @click="selectedSection = 'intro'">
                <div
                    :class="{'faq-background':true, 'faq-background-active':selectedSection === 'intro'}">
                  {{ tableOfContent.fogyaszd_feleloseggel_1 }}
                </div>
                <img class="icon--faq-arrow" src="@/assets/images/icons/btn-arrow-grey.svg"/>
              </a>
              <a href="#fogyaszd_feleloseggel_2" v-scroll-to="'#fogyaszd_feleloseggel_2'"
                 class="divider--faq text--faq-menu-text padding-none faq-btn-container-margin faq-btn-container"
                 @click="selectedSection = 'intro'">
                <div
                    :class="{'faq-background':true, 'faq-background-active':selectedSection === 'intro'}">
                  Mit
                  {{ tableOfContent.fogyaszd_feleloseggel_2 }}
                </div>
                <img class="icon--faq-arrow" src="@/assets/images/icons/btn-arrow-grey.svg"/>
              </a>
              <a href="#fogyaszd_feleloseggel_3" v-scroll-to="'#fogyaszd_feleloseggel_3'"
                 class="divider--faq padding-none text--faq-menu-text faq-btn-container faq-btn-container-margin"
                 @click="selectedSection = 'intro'">
                <div
                    :class="{'faq-background':true, 'faq-background-active':selectedSection === 'intro'}">
                  {{ tableOfContent.fogyaszd_feleloseggel_3 }}
                </div>
                <img class="icon--faq-arrow" src="@/assets/images/icons/btn-arrow-grey.svg"/>
              </a>
            </div>
            <div class="fogyaszdfeleloseggel-divider-menu">
              <p class="text--faq-menu-title padding-40--top text--gold">Mértékkel sopronizz!</p>
              <a href="#fogyaszd_feleloseggel_4" v-scroll-to="'#fogyaszd_feleloseggel_4'"
                 class="divider--faq padding-none text--faq-menu-text faq-btn-container faq-btn-container-margin"
                 @click="selectedSection = 'intro'">
                <div
                    :class="{ 'faq-background':true, 'faq-background-active':selectedSection === 'intro'}">
                  {{ tableOfContent.fogyaszd_feleloseggel_4 }}
                </div>
                <img class="icon--faq-arrow" src="@/assets/images/icons/btn-arrow-grey.svg"/>
              </a>
            </div>
            <div>
              <p class="text--faq-menu-title padding-40--top text--gold">Gyakori kérdések</p>
              <a href="#fogyaszd_feleloseggel_5" v-scroll-to="'#fogyaszd_feleloseggel_5'"
                 class="divider--faq padding-none text--faq-menu-text faq-btn-container faq-btn-container-margin"
                 @click="selectedSection = 'intro'">
                <div
                    :class="{'faq-background':true, 'faq-background-active':selectedSection === 'intro'}">
                  {{ tableOfContent.fogyaszd_feleloseggel_5 }}
                </div>
                <img class="icon--faq-arrow" src="@/assets/images/icons/btn-arrow-grey.svg"/>
              </a>
              <a href="#fogyaszd_feleloseggel_6" v-scroll-to="'#fogyaszd_feleloseggel_6'"
                 class="divider--faq padding-none text--faq-menu-text faq-btn-container faq-btn-container-margin"
                 @click="selectedSection = 'intro'">
                <div
                    :class="{'faq-background':true, 'faq-background-active':selectedSection === 'intro'}">
                  {{ tableOfContent.fogyaszd_feleloseggel_6 }}
                </div>
                <img class="icon--faq-arrow" src="@/assets/images/icons/btn-arrow-grey.svg"/>
              </a>
              <a href="#fogyaszd_feleloseggel_7" v-scroll-to="'#fogyaszd_feleloseggel_7'"
                 class="divider--faq padding-none text--faq-menu-text faq-btn-container faq-btn-container-margin"
                 @click="selectedSection = 'intro'">
                <div
                    :class="{'faq-background':true, 'faq-background-active':selectedSection === 'intro'}">
                  {{ tableOfContent.fogyaszd_feleloseggel_7 }}
                </div>
                <img class="icon--faq-arrow" src="@/assets/images/icons/btn-arrow-grey.svg"/>
              </a>
              <a href="#fogyaszd_feleloseggel_8" v-scroll-to="'#fogyaszd_feleloseggel_8'"
                 class="padding-none text--faq-menu-text faq-btn-container faq-btn-container-margin"
                 @click="selectedSection = 'intro'">
                <div
                    :class="{'faq-background':true, 'faq-background-active':selectedSection === 'intro'}">
                  {{ tableOfContent.fogyaszd_feleloseggel_8 }}
                </div>
                <img class="icon--faq-arrow" src="@/assets/images/icons/btn-arrow-grey.svg"/>
              </a>
            </div>
          </div>

          <b-card no-body class="fogyaszdfeleloseggel-menu-mobile">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-faq class="fogyaszdfeleloseggel-menu-mobile-header">
                Fogyaszd felelősséggel
                <div class="fogyaszdfeleloseggel-mobile-icon"/>

              </b-button>
            </b-card-header>
            <b-collapse id="accordion-faq" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <b-card-text>
                  <a href="#faq_intro" class="divider--faq  padding-none faq-btn-container"
                     @click="selectedSection = 'intro'">
                    <div
                        :class="{'flex-row': true, 'faq-background':true, 'faq-background-active':selectedSection === 'intro'}">
                      <p class="text--black margin-none align-self-center">
                      </p>
                    </div>
                  </a>
                  <p class="text--faq-menu-title--mobile">Tények</p>
                  <div class="fogyaszdfeleloseggel-divider-menu">
                    <a href="#fogyaszd_feleloseggel_1" v-scroll-to="'#fogyaszd_feleloseggel_1'"
                       class="text--faq-menu-text--mobile faq-btn-container faq-btn-container-margin"
                       @click="selectedSection = 'intro'">
                      <div
                          :class="{'faq-background':true, 'faq-background-active':selectedSection === 'intro'}">
                        {{ tableOfContent.fogyaszd_feleloseggel_1 }}
                      </div>
                      <img class="icon--faq-arrow" src="@/assets/images/icons/btn-arrow-grey.svg"/>
                    </a>
                    <a href="#fogyaszd_feleloseggel_2" v-scroll-to="'#fogyaszd_feleloseggel_2'"
                       class="divider--faq text--faq-menu-text--mobile padding-none faq-btn-container faq-btn-container-margin"
                       @click="selectedSection = 'intro'">
                      <div
                          :class="{'faq-background':true, 'faq-background-active':selectedSection === 'intro'}">
                        {{ tableOfContent.fogyaszd_feleloseggel_2 }}
                      </div>
                      <img class="icon--faq-arrow" src="@/assets/images/icons/btn-arrow-grey.svg"/>
                    </a>
                    <a href="#fogyaszd_feleloseggel_3" v-scroll-to="'#fogyaszd_feleloseggel_3'"
                       class="divider--faq padding-none text--faq-menu-text--mobile faq-btn-container faq-btn-container-margin"
                       @click="selectedSection = 'intro'">
                      <div
                          :class="{'faq-background':true, 'faq-background-active':selectedSection === 'intro'}">
                        {{ tableOfContent.fogyaszd_feleloseggel_3 }}
                      </div>
                      <img class="icon--faq-arrow" src="@/assets/images/icons/btn-arrow-grey.svg"/>
                    </a>
                  </div>
                  <div class="fogyaszdfeleloseggel-divider-menu">
                    <p class="text--faq-menu-title--mobile padding-40--top">Mértékkel sopronizz!</p>
                    <a href="#fogyaszd_feleloseggel_4" v-scroll-to="'#fogyaszd_feleloseggel_4'"
                       class="divider--faq padding-none text--faq-menu-text--mobile faq-btn-container faq-btn-container-margin"
                       @click="selectedSection = 'intro'">
                      <div
                          :class="{'faq-background':true, 'faq-background-active':selectedSection === 'intro'}">
                        {{ tableOfContent.fogyaszd_feleloseggel_4 }}
                      </div>
                      <img class="icon--faq-arrow" src="@/assets/images/icons/btn-arrow-grey.svg"/>
                    </a>
                  </div>
                  <div>
                    <p class="text--faq-menu-title--mobile padding-40--top">Gyakori kérdések</p>
                    <a href="#fogyaszd_feleloseggel_5" v-scroll-to="'#fogyaszd_feleloseggel_5'"
                       class="divider--faq padding-none text--faq-menu-text--mobile faq-btn-container faq-btn-container-margin"
                       @click="selectedSection = 'intro'">
                      <div
                          :class="{'faq-background':true, 'faq-background-active':selectedSection === 'intro'}">
                        {{ tableOfContent.fogyaszd_feleloseggel_5 }}
                      </div>
                      <img class="icon--faq-arrow" src="@/assets/images/icons/btn-arrow-grey.svg"/>
                    </a>
                    <a href="#fogyaszd_feleloseggel_6" v-scroll-to="'#fogyaszd_feleloseggel_6'"
                       class="divider--faq padding-none text--faq-menu-text--mobile faq-btn-container faq-btn-container-margin"
                       @click="selectedSection = 'intro'">
                      <div
                          :class="{'faq-background':true, 'faq-background-active':selectedSection === 'intro'}">
                        {{ tableOfContent.fogyaszd_feleloseggel_6 }}
                      </div>
                      <img class="icon--faq-arrow" src="@/assets/images/icons/btn-arrow-grey.svg"/>
                    </a>
                    <a href="#fogyaszd_feleloseggel_7" v-scroll-to="'#fogyaszd_feleloseggel_7'"
                       class="divider--faq padding-none text--faq-menu-text--mobile faq-btn-container faq-btn-container-margin"
                       @click="selectedSection = 'intro'">
                      <div
                          :class="{'faq-background':true, 'faq-background-active':selectedSection === 'intro'}">
                        {{ tableOfContent.fogyaszd_feleloseggel_7 }}
                      </div>
                      <img class="icon--faq-arrow" src="@/assets/images/icons/btn-arrow-grey.svg"/>
                    </a>
                    <a href="#fogyaszd_feleloseggel_8" v-scroll-to="'#fogyaszd_feleloseggel_8'"
                       class="divider--faq padding-none text--faq-menu-text--mobile faq-btn-container faq-btn-container-margin"
                       @click="selectedSection = 'intro'">
                      <div
                          :class="{'faq-background':true, 'faq-background-active':selectedSection === 'intro'}">
                        {{ tableOfContent.fogyaszd_feleloseggel_8 }}
                      </div>
                      <img class="icon--faq-arrow" src="@/assets/images/icons/btn-arrow-grey.svg"/>
                    </a>
                  </div>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <div class="fogyaszdfeleloseggel-content col-md-12 col-lg-6">
            <h3 class="text--gold title-margin" v-inview="'fade-up'">Tények</h3>
            <div class="fogyaszdfeleloseggel-divider padding-22" id="fogyaszd_feleloseggel_1"
                 v-html="faqs.fogyaszd_feleloseggel_1 && faqs.fogyaszd_feleloseggel_1.description" v-inview="'fade-up'"></div>
            <div class="fogyaszdfeleloseggel-divider" id="fogyaszd_feleloseggel_2"
                 v-html="faqs.fogyaszd_feleloseggel_2 && faqs.fogyaszd_feleloseggel_2.description" v-inview="'fade-up'"></div>
            <div class="fogyaszdfeleloseggel-divider" id="fogyaszd_feleloseggel_3"
                 v-html="faqs.fogyaszd_feleloseggel_3 && faqs.fogyaszd_feleloseggel_3.description" v-inview="'fade-up'"></div>
            <h3 class="margin-none text--gold padding-60--top" v-inview="'fade-up'">Mértékkel Sopronizz!</h3>
            <div class="fogyaszdfeleloseggel-divider" id="fogyaszd_feleloseggel_4"
                 v-html="faqs.fogyaszd_feleloseggel_4 && faqs.fogyaszd_feleloseggel_4.description.replace(/^\s*<h4>.*<\/h4>/i,'')" v-inview="'fade-up'"></div>
            <h3 class="margin-none text--gold padding-60--top" v-inview="'fade-up'">Gyakran Intézett Kérdések</h3>
            <div class="fogyaszdfeleloseggel-divider padding-22" id="fogyaszd_feleloseggel_5"
                 v-html="faqs.fogyaszd_feleloseggel_5 && faqs.fogyaszd_feleloseggel_5.description" v-inview="'fade-up'"></div>
            <div class="fogyaszdfeleloseggel-divider" id="fogyaszd_feleloseggel_6"
                 v-html="faqs.fogyaszd_feleloseggel_6 && faqs.fogyaszd_feleloseggel_6.description" v-inview="'fade-up'"></div>
            <div class="fogyaszdfeleloseggel-divider" id="fogyaszd_feleloseggel_7"
                 v-html="faqs.fogyaszd_feleloseggel_7 && faqs.fogyaszd_feleloseggel_7.description" v-inview="'fade-up'"></div>
            <div class="padding-60--top" id="fogyaszd_feleloseggel_8"
                 v-html="faqs.fogyaszd_feleloseggel_8 && faqs.fogyaszd_feleloseggel_8.description" v-inview="'fade-up'"></div>
          </div>
        </div>
      </div>
    </div>
    <PageLoading /> 
  </div>
</template>

<script>
import Ah from "@/mixins/apiHandlerMI";
import PageLoading from '@/components/layout/PageLoading.vue'

export default {
  mixins: [Ah],
  data: () => ({
    selectedSection: undefined,
    faqs: {},
    tableOfContent: {}
  }),  
  components:{
    PageLoading
  },
  mounted() {
    const _this = this
    const moduleIdentifiers = ['fogyaszd_feleloseggel_1', 'fogyaszd_feleloseggel_2', 'fogyaszd_feleloseggel_3',
      'fogyaszd_feleloseggel_4', 'fogyaszd_feleloseggel_5', 'fogyaszd_feleloseggel_6',
      'fogyaszd_feleloseggel_7', 'fogyaszd_feleloseggel_8'];
    _this.get('module',
        {
          params: {
            relations: 1,
            where: [
              ['where', 'status', '1'],
              ['where_in', 'identifier', moduleIdentifiers],
            ]
          }
        },
        function (resp) {
          _this.faqs = resp.data.module.reduce((acc, m) => {
            acc[m.identifier] = m;
            return acc;
          }, {});
          _this.tableOfContent = resp.data.module.reduce((acc, m) => {
            acc[m.identifier] = m.description.match(/^\s*<h4>(.*)<\/h4>/i)[1].trim();
            return acc;
          }, {})
        },
        function (err) {
          console.log(err)
          _this.faqs = {}
        }
    )

    //meta tags
    this.setMetaTags({
        title: 'Soproni - Fogyaszd felelősséggel', 
        desc: null, 
        keywords: null, 
        ogType: 'website', 
        ogTitle: 'Soproni - Fogyaszd felelősséggel',                         
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc:null
    })
  }
}
</script>

<style lang="scss">
*, *:before, *:after {
  box-sizing: border-box;
}

[data-v-sticky-container] {
  position: relative;
  display: flex;
}

.sticky {
  width: 400px;

  .inner {
    display: flex;
    height: 1080px;
  }

  &.is-affixed {
    > * {
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
